.header{
    margin-top: 1rem;
}
.headerItem{
    --border-width: 0;
}

.menuButton {
    --background: white;
    margin-left: 1rem;
}

.listTitle {
    margin-left: 2rem;
    margin-bottom: 2rem;
}

.main-grid {
    padding-top: 20px;
}


.graph-data-card{
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: auto; /* Adjust height as necessary */
    margin-bottom: 1em; /* Adds space below each card */
    margin-top: 1em;
    /* gap: 1em; */
}

.graph-data-card-item {
    width: 23.5%;
}

.workzone-data-card{
    margin: 1em;
}


.padding-for-map{
    padding: 10px;
}