@media only screen and (min-width: 1024px) {
  .download-card {
    width: 75%;
    margin: 3em auto;
  }
}

@media only screen and (max-width: 768px) {
  .download-card {
    margin: 3em 1em;
  }

  .download-info {
    display: none;
  }

  .query-info {
    display: none;
  }
}

.trash {
  width: 2em;
  height: 2em;
  cursor: pointer;
}

.download-card {
  text-align: center;
  overflow: visible;
}

.download-header {
  background-color: var(--ion-color-dark);
  color: var(--ion-color-dark-contrast);
  padding: 0.8em 0;
}

.download-row {
  padding: 1em 0.3em 0.6em 0.3em;
  align-items: center;
}

.query-button {
  width: 15em;
}

.download-status {
  width: 15em;
  margin: auto;
}

.card-content {
  padding: 0;
}

.grid {
  padding: 0;
}
