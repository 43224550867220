.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  border-radius: 3px;
  margin: -3rem 0 0 0;
  background-color: var(--ion-color-green);
  box-shadow: 2px 7px 8px rgba(var(--ion-color-green-rgb), 0.2);
}

.tomorrow-logo {
  width: 15em;
}

.map-card {
  overflow: visible;
  margin: 2rem 0.5rem;
}

.title {
  overflow: visible;
}

.flag {
  width: 2rem;
  margin-right: 10px;
}

.recenter {
  margin: 0.5em;
  z-index: 1;
}

.map-title {
  margin: 1em;
}

.map-icon {
  cursor: pointer;
}

.marker-icon {
  height: 1.5em;
  width: 1.5em;
}

.recenter-container {
  width: 12em;
  display: flex;
  flex-direction: column;
}
