.rule {
  height: 0.05em;
  width: 95%;
  margin: auto;
  background-color: #d7d8da;
}

.blue,
.orange,
.red {
  position: relative;
  height: 100%;
  width: 95%;
  padding: 10px;
  border-radius: 5px;
  margin: -2rem auto auto;
}

.blue {
  background-color: var(--ion-color-blue);
  box-shadow: 0 7px 5px rgba(var(--ion-color-blue-rgb), 0.2);
}

.red {
  background-color: var(--ion-color-red);
  box-shadow: 0 7px 5px rgba(var(--ion-color-red-rgb), 0.2);
}

.orange {
  background-color: var(--ion-color-orange);
  box-shadow: 0 7px 5px rgba(var(--ion-color-orange-rgb), 0.2);
}

.icon {
  margin: 0 0.5em 0 0;
}

.graph-card {
  overflow: visible;
  margin: 1rem 0;
}

.card-content {
  height: 90%;
  overflow: visible;
}
