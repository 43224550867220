.link-container {
    width: 80em;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.link-card {
    width: 15em;
    height: 15em;
}

.link-card-content {
    height: 11em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.link-content {
    display: flex;
    justify-content: center;
}
