.div-title{
    color: var(--ion-color-dark);
    font-size: x-large;
}

.div-subtitle{
    color: var(--ion-color-medium);
    font-size: large;
}

.info-item{
    padding-bottom: 2em;
}

ion-text{
    font-size: large;
}

ion-label{
    font-size: medium;
}

.county-container {
    display: flex;
    justify-content: flex-start;
}

.subscription-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.subscription {
    width: 15em;
    height: 15em;
    margin-right: 2em;
}

.sub-checkbox {
    width: 5em;
    height: 5em;
    margin-top: 1em;
}

.password-break {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.reset-submit {
    width: 15em;
    height: 2.75em;
}

.password-reset {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.space-between{
    margin-bottom: 2em;
}

.space-between-top {
    margin-top: 2em;
}

.admin{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 1em;
}

.admin-submit{
    margin-top: 1em;
    width: 15em;
}

.applied-user {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

i {
    color: grey;
}

.privacy-preview{
    height: 40em;
    overflow: hidden;
    background: -webkit-linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
