.notification-title-coloring {
    background-color: var(--ion-color-purple);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-purple-rgb), 0.2);
}

@media only screen and (min-width: 1024px) {
    .notification-page-card {
        width: 75%;
        margin: 3em auto;
    }
}

@media only screen and (max-width: 768px) {
    .notification-page-card {
        margin: 3em 0;
    }
}

.no-notification-text{
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 1em;
    margin: 5em 1em;
}

.no-notification-label{
    font-size: x-large;
    margin: 0.5em auto;
}
