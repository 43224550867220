@media only screen and (max-width: 1398px) {
    .file-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap-reverse;
    }
    .file-space {
        width: 20em;
    }
    .uploaded-file-space {
        width: 20em;
        overflow-x: visible;
    }
    .upload-title {
        display: none;
    }
}

@media only screen and (min-width: 1399px) {
    .file-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap-reverse;
    }
    .file-space {
        position: absolute;
        left: 1em;
        width: 20em;
        height: 100%;
    }
    .uploaded-file-space {
        position: absolute;
        right: 1em;
        width: 20em;
        height: 100%;
    }
    .upload-title {
        width: 7.5em;
        font-size: 1.5em;
        padding-left: .5em;
        margin-bottom: 1em;
    }
    .upload-title-mobile {
        display: none;
    }
}

.select-width {
    min-width: 30em;
}

.dropdown-container {
    height: 20em;
    margin-bottom: 3em;
}

.dropdown-form-div {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 1em;
}

.overflow {
    overflow-y: auto;
}

.upload-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.file-input {
    display: none;
}

.details-card {
    width: 50em;
}

.tag-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 1em;
}

.tag {
    height: 2em;
    margin: .5em;
    padding: .5em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid grey 1px;
    border-radius: 1em 1em;
    background-color: lightgrey;

}

.file {
    height: 8em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    border: solid lightgrey 1px;
    border-radius: .5em;
    padding: 1em;
    margin-bottom: 1em;
    cursor: pointer;
}

.uploaded-file {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border: solid lightgrey 1px;
    border-radius: .5em;
    padding: 1em;
    margin-bottom: 1em;
}

.file-name {
    min-width: 5em;
}

.file-remove {
    width: 1.5em;
    height: 1.5em;
    position: relative;
    bottom: 0;
    left: 14em;
    cursor: pointer;
}

.card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: visible;
}

.file-card {
    max-width: 50em;
    width: 50em;
}

.dropzone {
    width: 100%;
    height: 40em;
    border: solid lightpink 1px;
    border-radius: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1em 0 1em 0;
}

.upload-container {
    text-align: center;
}
