.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-in-logo {
  cursor: pointer;
}

.ridsi-icon {
  width: 4em;
  height: 4em;
  margin-right: 0.5em;
}

.header {
  height: 6em;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 769px) {
  .header{
    padding-right: 20vw !important;
    padding-left: 20vw !important;
  }
}

.footer {
  position: absolute;
  z-index: 0;
  bottom: 0;
}

.formItem {
  align-items: center;
}

.logo-container {
  display: flex !important;
  justify-content: space-evenly;
}

.login-card-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.login-label {
  font-size: 1.25em !important;
}

.links {
  width: 100%;
  text-align: center;
}

.link {
  --color: palevioletred;
}

.error-message {
  color: red;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: center;
}

.center {
  text-align: center;
}

/* Everything above mobile devices */
@media only screen and (min-width: 769px) {
  .card,
  .reset-card {
    width: 25em;
    min-height: 33em;
    padding-top: 10em;
    z-index: 1;
  }

  .reset-card {
    min-height: 15em;
    padding-top: 4em;
  }

  .inner-card,
  .reset-inner-card {
    position: relative;
    top: 10em;
    z-index: 2;
    height: 10em;
    width: 23em;
  }

  .reset-inner-card {
    height: 6em;
    top: 5em;
  }
}

/* Mobile devices and below */
@media only screen and (max-width: 769px) {
  .card,
  .reset-card {
    position: relative;
    z-index: 1;
    bottom: 10em;
    width: 25em;
    min-height: 33em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .reset-card {
    min-height: 15em;
    padding-top: 4em;
    margin-top: 5em;
  }

  .inner-card,
  .reset-inner-card {
    z-index: 2;
    height: 10em;
    width: 23em;
  }

  .reset-inner-card {
    height: 6em;
  }
}
