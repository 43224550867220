.loading-center {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ion-spinner {
    transform: scale(3);
}
