.outer-dev-card {
    overflow: visible;
    min-height: 21em;
}

@media only screen and (max-width: 1024px) {
    .outer-dev-card{
        margin: 1.5em 0;
    }
}

@media only screen and (min-width: 1024px) {
    .outer-dev-card{
        margin: 1.5em 0.5em;
    }
}

.about-row-top-border {
    border-top: 1px solid var(--ion-color-medium);
}

.about-link-row {
    padding: 0.5em;
    align-items: center;
}

.about-link-icon {
    margin: 0 0.5em 0 0;
}

.about-role-area, .about-description-area{
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    text-align: left;
}

.about-role-area {
    height: 3em;
}

@media only screen and (max-width: 768px) {
    .about-description-area {
        min-height: 12em;
    }
}

@media only screen and (min-width: 768px) {
    .about-description-area {
        height: 12em;
    }
}
