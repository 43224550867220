.notification-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
    width: 4rem;
    border-radius: 3px;
    margin: -2rem 0 0 1.5em;
}

.outer-header {
    overflow: visible;
    display: flex;
}

.notification-header {
    margin: 0.5em 1em;
    font-size: large;
}

.notification-card {
    overflow: visible;
    margin: 3em 1em;
}

.card-footer{
    display: flex;
    align-items: center;
}

.download {
    background-color: var(--ion-color-blue);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-blue-rgb), 0.2);
}

.crash {
    background-color: var(--ion-color-red);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-red-rgb), 0.2);
}

.weather {
    background-color: var(--ion-color-orange);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-orange-rgb), 0.2);
}

.road {
    background-color: var(--ion-color-green);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-green-rgb), 0.2);
}

.construction {
    background-color: var(--ion-color-yellow);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-yellow-rgb), 0.2);
}

.jam {
    background-color: var(--ion-color-purple);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-purple-rgb), 0.2);
}
