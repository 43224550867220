.about-title-coloring {
    background-color: var(--ion-color-pink);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-orange-rgb), 0.2);
}

@media only screen and (min-width: 768px) {
    .about-section-selectors {
        display: flex;
        justify-content: center;
    }
}

