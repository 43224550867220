.profile-title-coloring {
    background-color: var(--ion-color-pink);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-pink-rgb), 0.2);
}

.secondary-header{
    margin: 0.5em 1em;
    padding: 1rem 0;
    border-bottom: 0.1em solid var(--ion-color-medium);
}

@media only screen and (max-width: 768px) {
    .profile-content{
        padding: 0 !important;
    }
}

.main-content{
    display: flex;
    flex-direction: column;
}

.quick-nav-menu{
    border-left: 0.1em solid var(--ion-color-light);
}

.quick-nav-title{
    color: white;
    font-size: x-large;
}

