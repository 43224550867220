.about-member-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7rem;
    width: 7rem;
    overflow: hidden;
    border-radius: 3px;
    margin: -2rem 0 0 1rem;
    background-color: var(--ion-color-medium);
    box-shadow: 2px 7px 8px rgba(var(--ion-color-medium-rgb), 0.2);
}

.about-header-card-title{
    color: var(--ion-color-dark);
    font-size: x-large;
}

.about-card-subtitle-container{
    margin-bottom: 1em;
}

.about-card-subtitle{
    color: var(--ion-color-medium);
    font-size: x-large;
}

.agency-logos{
    margin: 0 2em;
}

.segment-text-column {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 1024px) {
    .about-segment-card{
        margin: 1em 0;
    }
}

@media only screen and (min-width: 1024px) {
    .segment-text-column {
        padding: 1em 1em 1em 4em;
    }
}

.agency-column {
    display: flex;
    margin-bottom: 3em;
}
