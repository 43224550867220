.autocomplete-container {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
}
.autocomplete {
    width: 100%;
    border-radius: 6px;
    box-shadow: 2px 2px lightgrey;
}

.autocomplete-item {
    border-radius: 6px;
    cursor: pointer;
}

.notification-count {
    width: 1.75em;
    height: 1.75em;
    position: absolute;
    z-index: 1000;
    bottom: 22px;
    right: 0;
}

.notification-number {
    width: 1.75em;
    height: 2em;
    font-size: .8em;
    padding-top: 3px;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 1001;
    top: 3px;
    right: 4px;
    color: white;
    text-shadow: black 1px 1px;
    cursor: pointer;
}

@media only screen and (max-width: 1030px) {
    .tool-bar {
        width: 100%;

    }

    .header-title {
        display: none;
    }
}

.header-title-container {
    min-width: 8em;
    max-width: 12em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.ridsi-icon-header {
    width: 2em;
    height: 2em;
    box-shadow: 2px 10px 5px gray;
}
