.viz-title-coloring {
  background-color: var(--ion-color-yellow);
  box-shadow: 2px 7px 8px rgba(var(--ion-color-yellow-rgb), 0.2);
  color: grey !important;
}

.map-div {
  text-align: left !important;
}

@media only screen and (min-width: 1023px) {
  .box {
    display: flex;
    justify-content: space-around;
  }

  .controls-box {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .map-div {
    width: 65%;
  }

  .controls-div {
    max-height: 44em;
  }
}

.weather-div {
  min-height: 14em;
}

.key {
  width: 100%;
  min-height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.key-box {
  min-width: 3em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.red-key {
  background-color: red;
}

.orangered-key {
  background-color: orangered;
}

.gold-key {
  background-color: gold;
  color: black;
}

.greenyellow-key {
  background-color: greenyellow;
  color: black;
}

.palegreen-key {
  background-color: palegreen;
  color: black;
}

.mediumseagreen-key {
  background-color: mediumseagreen;
}

.forestgreen-key {
  background-color: forestgreen;
}

