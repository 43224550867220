.live-title-coloring {
  background-color: var(--ion-color-blue);
  box-shadow: 2px 7px 8px rgba(var(--ion-color-blue-rgb), 0.2);
}

.cctv-div-title{
  color: var(--ion-color-dark);
  font-size: x-large;
}

.camera-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  flex-wrap: wrap-reverse;
}

.main-camera {
  width: 100em;
  height: 100%;
  display: flex;
  justify-content: center;
}

.selector-div{
  padding-left: 1em;
  border-bottom: .1em solid var(--ion-color-medium);
  display: flex;
  justify-content: space-between;
}

.submission-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.live-recenter-alignment {
  text-align: left !important;
}

.loading {
  color: var(--ion-color-danger) !important;
}

.submission-card {
  width: 30em;
}

.submit-cameras {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.submission-input {
  border: solid black 2px;
}

.main-row {
  width: 100%;
  height: 40em;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 1983px) {
  .camera-index {
    width: 35em;
    max-height: 80em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 1983px) {
  .camera-index {
    width: 100%;
    max-height: 55em;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 1023px) {
  .map-container {
    width: 75%;
    margin: 1em auto 2em auto;
  }
}

@media only screen and (max-width: 1023px) {
  .map-container{
    margin: 1em 0 2em 0;
  }
}
