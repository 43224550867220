
/* common properties for all cards*/

.main-container{
    --background: white;
    overflow: visible;
}

.my-card-header{
    display: flex;
    background-color: antiquewhite;
    padding: 10px;
}

.icon {
    width: 1.5em;
    height: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease; 
    cursor: pointer; 
    transform: scale(2.5);
}

.my-card-title{
    padding-top: 0.25em;
    font-weight: bold;
    color: black;
    margin-left: auto;
}

.gauge-container{
    display: flex;
    justify-content: center;
    align-items: center; 
    /* padding: 2em; */
    margin: 2em;
    height: 14em;
   
}

.center-title{
    display: flex;
    justify-content: center;
    color: black;
}

.counties-list {
    height: 15.6em;
    max-height: 15.6em;
    margin-top: 1.1em;
    overflow-y: auto;
    padding: 1em;
}

.county-and-bar {
    padding: 0.5em;
}

.county-and-percentage{
    display: flex;
    color: black;
}

.percentage{
    margin-left: auto;
}

.last-updated{
    display: flex;
    justify-content: center;
    padding: 0.25em;
    background-color: antiquewhite;
    color: black;
}

.bigger-alert{  
    --max-width: auto !important; 
}




/*****************************************************************************************************************/
/* styles for crashes data card */
.crashes-icon:hover {
    background-color: rgb(194, 3, 82); /* Lighter color on hover */
}

.crashes-icon{
    background-color: var(--ion-color-orange);
}


/*****************************************************************************************************************/
/* styles for clearance data card */

.clearance-icon{
    background-color: green;
}

.clearance-icon:hover {
    background-color: rgb(149, 149, 13);
}

.clearance-line-graph{
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 14em;
    /* padding: 1em; */
    margin: 2em;
   
}

.county-and-arrow{
    display: flex;
    margin: 5px;
    padding: 3px;
    color: black;
}

.circle-dot{
    margin-top: 4px;
    margin-right: 10px;
}

.arrow{
    margin-left: auto;
    margin-right: 40px;
    
}

/*****************************************************************************************************************/
/* styles for freeway data card */

.freeway-icon{
    background-color: var(--ion-color-red);
}

.freeway-icon:hover {
    background-color: rgb(212, 3, 153);

}


/*****************************************************************************************************************/
/* styles for congestion data card */

.congestion-icon{
    background-color: var(--ion-color-blue);
}

.congestion-icon:hover {
    background-color: rgb(31, 24, 220);
}

.congestion-doughnut-table{
    display: flex;
    gap: 2em;
    margin: 2em;
}

.congestion-doughnut{
    display: flex;
    height: 14em;
    width: 15em;
    padding-top: 2em;
    padding-bottom: 2em;
}


.congestion-table{
    margin-left: 1em;
    margin-top: 4em;
    margin-right: 1em;
    color: antiquewhite;
}


/*****************************************************************************************************************/
/* styles for workzone data card */

.workzone-icon{
    background-color: #8b4513;
}

.workzone-icon:hover {
    background-color: #e06f1f; 
}

/* .line-graph-class{
    padding-top: 1em;
    height: 20em;
    width: 25em;
}

.line-and-text{
    display: flex;
}

.work-zone-info{
    color: black;
    margin-top: 3em;    
    margin-left: 1em;
    margin-right: 0.5em;
}


.scroll-container{
    max-height: 18em;
    overflow-y: auto;
    display: flex;
} */

.workzone-graphs{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.workzone-line-graph{
    height: 20em;
    width: 30em; 
}

.workzone-bar-chart{
    height: 20em;
    width: 30em; 
}

.work-zone-info
{
    font-size: 1.2em;
    /* font-weight: bold; */
    color: black;
}
