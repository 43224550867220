.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  padding: 2em 0;
}

.verification-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.route-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.route-button {
  width: 18em;
}

/* Everything above mobile devices */
@media only screen and (min-width: 769px) {
  .card-registration {
    width: 45em;
  }

  .card-container-registration {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 8em;
  }
}

/* Mobile devices and below */
@media only screen and (max-width: 769px) {
  .card-registration {
    width: 25em;
  }

  .route-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .card-container-registration {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
