/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** pink **/
  --ion-color-pink: #dd2366;
  --ion-color-pink-rgb: 221,35,102;
  --ion-color-pink-contrast: #ffffff;
  --ion-color-pink-contrast-rgb: 255,255,255;
  --ion-color-pink-shade: #c21f5a;
  --ion-color-pink-tint: #e03975;

  /** Mat colors **/
  --ion-color-orange: #FC930A;
  --ion-color-orange-rgb: 252, 147, 10;
  --ion-color-orange-contrast: #ffffff;
  --ion-color-orange-contrast-rgb: 255,255,255;
  --ion-color-orange-shade: #de8109;
  --ion-color-orange-tint: #fc9e23;

  --ion-color-green: #4CAF50;
  --ion-color-green-rgb: 76, 175, 80;
  --ion-color-green-contrast: #ffffff;
  --ion-color-green-contrast-rgb: 255,255,255;
  --ion-color-green-shade: #439a46;
  --ion-color-green-tint: #5eb762;

  --ion-color-red: #E73E3A;
  --ion-color-red-rgb: 231, 62, 58;
  --ion-color-red-contrast: #ffffff;
  --ion-color-red-contrast-rgb: 255,255,255;
  --ion-color-red-shade: #cb3733;
  --ion-color-red-tint: #e9514e;

  --ion-color-purple: #9c27b0;
  --ion-color-purple-rgb: 156, 39, 176;
  --ion-color-purple-contrast: #ffffff;
  --ion-color-purple-contrast-rgb: 255,255,255;
  --ion-color-purple-shade: #6d1b7b;
  --ion-color-purple-tint: #af52bf;

  --ion-color-yellow: #ffeb3b;
  --ion-color-yellow-rgb: 255, 235, 59;
  --ion-color-yellow-contrast: #000000;
  --ion-color-yellow-contrast-rgb: 0,0,0;
  --ion-color-yellow-shade: #b2a429;
  --ion-color-yellow-tint: #ffef62;

  --ion-color-blue: #07B1C6;
  --ion-color-blue-rgb: 7, 177, 198;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 255,255,255;
  --ion-color-blue-shade: #069cae;
  --ion-color-blue-tint: #20b9cc; 
  
  --ion-color-grey: #4d4d4d;
  --ion-color-grey-rgb: 144,144,144;
  --ion-color-grey-contrast: #ffffff;
  --ion-color-grey-contrast-rgb: 255,255,255;
  --ion-color-grey-shade: #787f80;
  --ion-color-grey-tint: #4d4f4f;
}

.ion-color-pink {
  --ion-color-base: var(--ion-color-pink);
  --ion-color-base-rgb: var(--ion-color-pink-rgb);
  --ion-color-contrast: var(--ion-color-pink-contrast);
  --ion-color-contrast-rgb: var(--ion-color-pink-contrast-rgb);
  --ion-color-shade: var(--ion-color-pink-shade);
  --ion-color-tint: var(--ion-color-pink-tint);
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

.ion-color-green {
  --ion-color-base: var(--ion-color-green);
  --ion-color-base-rgb: var(--ion-color-green-rgb);
  --ion-color-contrast: var(--ion-color-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-shade);
  --ion-color-tint: var(--ion-color-green-tint);
}

.ion-color-red {
  --ion-color-base: var(--ion-color-red);
  --ion-color-base-rgb: var(--ion-color-red-rgb);
  --ion-color-contrast: var(--ion-color-red-contrast);
  --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
  --ion-color-shade: var(--ion-color-red-shade);
  --ion-color-tint: var(--ion-color-red-tint);
}

.ion-color-purple {
  --ion-color-base: var(--ion-color-purple);
  --ion-color-base-rgb: var(--ion-color-purple-rgb);
  --ion-color-contrast: var(--ion-color-purple-contrast);
  --ion-color-contrast-rgb: var(--ion-color-purple-contrast-rgb);
  --ion-color-shade: var(--ion-color-purple-shade);
  --ion-color-tint: var(--ion-color-purple-tint);
}

.ion-color-yellow {
  --ion-color-base: var(--ion-color-yellow);
  --ion-color-base-rgb: var(--ion-color-yellow-rgb);
  --ion-color-contrast: var(--ion-color-yellow-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow-shade);
  --ion-color-tint: var(--ion-color-yellow-tint);
}

.ion-color-blue {
  --ion-color-base: var(--ion-color-blue);
  --ion-color-base-rgb: var(--ion-color-blue-rgb);
  --ion-color-contrast: var(--ion-color-blue-contrast);
  --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
  --ion-color-shade: var(--ion-color-blue-shade);
  --ion-color-tint: var(--ion-color-blue-tint);
}

.ion-color-grey {
  --ion-color-base: var(--ion-color-grey);
  --ion-color-base-rgb: var(--ion-color-grey-rgb);
  --ion-color-contrast: var(--ion-color-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-grey-shade);
  --ion-color-tint: var(--ion-color-grey-tint);
}

/* Global CSS items */

ion-toast::part(button) {
  background-color: var(--ion-color-primary);
  border-radius: .1em;
  color: white;
}

.toast-error {
  text-align: center;
}

.card-header-title-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  width: 15rem;
  padding: 0.5rem;
  border-radius: 3px;
  margin: -3rem 0 0 1rem;
  color: white;
}

.card-header-container {
  margin: 3em 1em;
  text-align: center;
  overflow: visible;
}

.card-container-padding{
  padding-bottom: 4em;
}

.react-dropdown-select-dropdown {
  z-index: 999999 !important;
  max-height: 15em !important;
  width: 30em !important;
}

/*@media (prefers-color-scheme: dark) {*/
/*  !**/
/*   * Dark Colors*/
/*   * -------------------------------------------*/
/*   *!*/

/*  body {*/
/*    --ion-color-primary: #428cff;*/
/*    --ion-color-primary-rgb: 66,140,255;*/
/*    --ion-color-primary-contrast: #ffffff;*/
/*    --ion-color-primary-contrast-rgb: 255,255,255;*/
/*    --ion-color-primary-shade: #3a7be0;*/
/*    --ion-color-primary-tint: #5598ff;*/

/*    --ion-color-secondary: #50c8ff;*/
/*    --ion-color-secondary-rgb: 80,200,255;*/
/*    --ion-color-secondary-contrast: #ffffff;*/
/*    --ion-color-secondary-contrast-rgb: 255,255,255;*/
/*    --ion-color-secondary-shade: #46b0e0;*/
/*    --ion-color-secondary-tint: #62ceff;*/

/*    --ion-color-tertiary: #6a64ff;*/
/*    --ion-color-tertiary-rgb: 106,100,255;*/
/*    --ion-color-tertiary-contrast: #ffffff;*/
/*    --ion-color-tertiary-contrast-rgb: 255,255,255;*/
/*    --ion-color-tertiary-shade: #5d58e0;*/
/*    --ion-color-tertiary-tint: #7974ff;*/

/*    --ion-color-success: #2fdf75;*/
/*    --ion-color-success-rgb: 47,223,117;*/
/*    --ion-color-success-contrast: #000000;*/
/*    --ion-color-success-contrast-rgb: 0,0,0;*/
/*    --ion-color-success-shade: #29c467;*/
/*    --ion-color-success-tint: #44e283;*/

/*    --ion-color-warning: #ffd534;*/
/*    --ion-color-warning-rgb: 255,213,52;*/
/*    --ion-color-warning-contrast: #000000;*/
/*    --ion-color-warning-contrast-rgb: 0,0,0;*/
/*    --ion-color-warning-shade: #e0bb2e;*/
/*    --ion-color-warning-tint: #ffd948;*/

/*    --ion-color-danger: #ff4961;*/
/*    --ion-color-danger-rgb: 255,73,97;*/
/*    --ion-color-danger-contrast: #ffffff;*/
/*    --ion-color-danger-contrast-rgb: 255,255,255;*/
/*    --ion-color-danger-shade: #e04055;*/
/*    --ion-color-danger-tint: #ff5b71;*/

/*    --ion-color-dark: #f4f5f8;*/
/*    --ion-color-dark-rgb: 244,245,248;*/
/*    --ion-color-dark-contrast: #000000;*/
/*    --ion-color-dark-contrast-rgb: 0,0,0;*/
/*    --ion-color-dark-shade: #d7d8da;*/
/*    --ion-color-dark-tint: #f5f6f9;*/

/*    --ion-color-medium: #989aa2;*/
/*    --ion-color-medium-rgb: 152,154,162;*/
/*    --ion-color-medium-contrast: #000000;*/
/*    --ion-color-medium-contrast-rgb: 0,0,0;*/
/*    --ion-color-medium-shade: #86888f;*/
/*    --ion-color-medium-tint: #a2a4ab;*/

/*    --ion-color-light: #222428;*/
/*    --ion-color-light-rgb: 34,36,40;*/
/*    --ion-color-light-contrast: #ffffff;*/
/*    --ion-color-light-contrast-rgb: 255,255,255;*/
/*    --ion-color-light-shade: #1e2023;*/
/*    --ion-color-light-tint: #383a3e;*/
/*  }*/

/*  !**/
/*   * iOS Dark Theme*/
/*   * -------------------------------------------*/
/*   *!*/

/*  .ios body {*/
/*    --ion-background-color: #000000;*/
/*    --ion-background-color-rgb: 0,0,0;*/

/*    --ion-text-color: #ffffff;*/
/*    --ion-text-color-rgb: 255,255,255;*/

/*    --ion-color-step-50: #0d0d0d;*/
/*    --ion-color-step-100: #1a1a1a;*/
/*    --ion-color-step-150: #262626;*/
/*    --ion-color-step-200: #333333;*/
/*    --ion-color-step-250: #404040;*/
/*    --ion-color-step-300: #4d4d4d;*/
/*    --ion-color-step-350: #595959;*/
/*    --ion-color-step-400: #666666;*/
/*    --ion-color-step-450: #737373;*/
/*    --ion-color-step-500: #808080;*/
/*    --ion-color-step-550: #8c8c8c;*/
/*    --ion-color-step-600: #999999;*/
/*    --ion-color-step-650: #a6a6a6;*/
/*    --ion-color-step-700: #b3b3b3;*/
/*    --ion-color-step-750: #bfbfbf;*/
/*    --ion-color-step-800: #cccccc;*/
/*    --ion-color-step-850: #d9d9d9;*/
/*    --ion-color-step-900: #e6e6e6;*/
/*    --ion-color-step-950: #f2f2f2;*/

/*    --ion-toolbar-background: #0d0d0d;*/

/*    --ion-item-background: #000000;*/

/*    --ion-card-background: #1c1c1d;*/
/*  }*/


/*  !**/
/*   * Material Design Dark Theme*/
/*   * -------------------------------------------*/
/*   *!*/

/*  .md body {*/
/*    --ion-background-color: #121212;*/
/*    --ion-background-color-rgb: 18,18,18;*/

/*    --ion-text-color: #ffffff;*/
/*    --ion-text-color-rgb: 255,255,255;*/

/*    --ion-border-color: #222222;*/

/*    --ion-color-step-50: #1e1e1e;*/
/*    --ion-color-step-100: #2a2a2a;*/
/*    --ion-color-step-150: #363636;*/
/*    --ion-color-step-200: #414141;*/
/*    --ion-color-step-250: #4d4d4d;*/
/*    --ion-color-step-300: #595959;*/
/*    --ion-color-step-350: #656565;*/
/*    --ion-color-step-400: #717171;*/
/*    --ion-color-step-450: #7d7d7d;*/
/*    --ion-color-step-500: #898989;*/
/*    --ion-color-step-550: #949494;*/
/*    --ion-color-step-600: #a0a0a0;*/
/*    --ion-color-step-650: #acacac;*/
/*    --ion-color-step-700: #b8b8b8;*/
/*    --ion-color-step-750: #c4c4c4;*/
/*    --ion-color-step-800: #d0d0d0;*/
/*    --ion-color-step-850: #dbdbdb;*/
/*    --ion-color-step-900: #e7e7e7;*/
/*    --ion-color-step-950: #f3f3f3;*/

/*    --ion-item-background: #1e1e1e;*/

/*    --ion-toolbar-background: #1f1f1f;*/

/*    --ion-tab-bar-background: #1f1f1f;*/

/*    --ion-card-background: #1e1e1e;*/
/*  }*/
/*}*/
